import React from "react"

const Cursor = ({ location, title, children }) => {
  return (
    <div className="c-cursor">
      <div className="c-cursor__wrapper">
        <span className="c-cursor__span">
            <span className="c-cursor__span-txt">
                DRAG
            </span>
        </span>
      </div>
    </div>
  )
}

export default Cursor