import React from "react"
import Header from "../../components/common/Header"
import CareersLink from "../../components/common/CareersLink"
import ArrowXReverse from "../../components/common/ArrowXReverse"
import Cursor from "../../components/common/Cursor"
import Footer from "../../components/common/Footer"
import { StaticImage } from "gatsby-plugin-image"
import { Link } from "gatsby"
import Img from "../../components/common/Img"

export default function NotFound() {
  return (
    <div className="contents">
      <Header />
      <div id="page">
        <div id="error" className="js-page">
          <main id="main">
            <div id="contents" className="contents__404">
              <Cursor />
              <section className="p-error-main">
                <div className="p-error-main__wrapper">
                  <div className="p-error-main__head">
                    <h2 className="p-error-main__head-ttl">Oops!</h2>
                  </div>
                  <div className="p-error-main__img">
                    <img
                      className="c-img__def p-error-main__img-item"
                      src="../asset/img/404/404.gif"
                      alt="踊っている様子"
                    />
                  </div>
                  <div className="p-error-main__content">
                    <h3 className="c-ttl__min p-error-main__content-ttl">
                      404 PAGE NOT FOUND
                    </h3>
                    <p className="c-txt__main p-error-main__content-txt">
                      おっと、ごめんなさい。 <br />
                      リンクが切れてしまったのか、このページにはもうあなたの求めるコンテンツは無くなってしまったようです。
                    </p>
                    <p className="c-txt__main p-error-main__content-txt">
                      でも、そんなにがっかりしなくても大丈夫！ <br />
                      そんなこともあろうかと、CRISPの優秀なデザイナーがイケてるアイデアを考えながら西麻布の路地裏を歩いているgifを置いておきました。このようにCRISPは今日も日々イケてる企業へと邁進しているのです。
                    </p>
                  </div>
                  <Link
                    to={"/"}
                    className="js-hover p-error-main__link"
                    data-hover-in-time="600"
                    data-hover-out-time="650"
                  >
                    <div className="c-link__main p-error-main__link-wrapper">
                      <span className="p-error-main__link-span">
                        <span className="c-link__main-inner">HOMEへ戻る</span>
                        <ArrowXReverse />
                      </span>
                    </div>
                  </Link>
                </div>
              </section>
              <CareersLink />
            </div>
          </main>
          <Footer />
        </div>
      </div>
    </div>
  )
}
